import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";

import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useGetProductsByCollection from "../hooks/useGetProductsByCollection";

import SEO from "../components/seo";
import { ALButton, ALLoading, ALLink } from "../components/ALComponents";
import RecommendedProducts from "../components/RecommendedProducts";
import { isBrowser } from "../context/helpers";
import { ComponentType } from "../constants/ComponentType";

function NotFoundPage() {
  const [checkedPage, updateCheckPage] = useState(false);
  const dict = useLocalizedSentenceDict();
  const handles = useGetProductsByCollection("bestsellers");
  const { sendReport } = useALError();

  useEffect(() => {
    if (isBrowser) {
      const _pathName = window.location.pathname;
      if (_pathName) {
        if (_pathName.includes("/collection/")) {
          // TMP SOLUTION
          navigate(_pathName.replace("/collection/", "/collections/"));
        } else {
          axios
            .get(`/.netlify/functions/get-redirect?url=${_pathName}`)
            .then((r) => {
              if (r && r.status === 200 && r.data.navigate !== null) {
                navigate(r.data.navigate);
              } else {
                updateCheckPage(true);
              }
            })
            .catch((error) => {
              console.log("404_redirect");
              console.log(error);
              sendReport(error, { name: "NotFoundPage", priority: "P4" });
              updateCheckPage(true);
            });
        }
      }
    }
  }, []);

  return checkedPage ? (
    <div style={{ height: "auto", maxWidth: "1280px", padding: "40px 20px", margin: "0 auto" }}>
      <SEO title="404 Not found | Ana Luisa Jewelry" />
      <h1 className="al_h1">{dict.get("This page is nowhere to be found.")}</h1>
      <p className="al_h3">{dict.get("Don't know where to start?")}</p>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "40px" }}>
        <ALLink to="/collections/bestsellers">
          <ALButton variant="primary">{dict.get("SHOP BEST SELLERS")}</ALButton>
        </ALLink>
      </div>
      {handles ? (
        <RecommendedProducts
          handles={handles}
          collection="best-sellers"
          title="Best Sellers"
          componentType={ComponentType.HP}
        />
      ) : null}
    </div>
  ) : (
    <div style={{ margin: "50px", display: "flex", alignItems: "center", flexDirection: "column" }}>
      <ALLoading />
      <p className="al_p">{dict.get("Loading...")}</p>
    </div>
  );
}

export default NotFoundPage;
